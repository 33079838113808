<template>
  <section class="imprint" data-nosnippet>
    <div class="imprint-container">
    <div>
      <router-link class="router-link" to="/">
          <div class="footer-logo-container">
            <div class="footer-brand-image"></div>
          </div>
      </router-link>
    </div>
    <div class="imprint-content">
      <div class="imprint-title title-text highlighted-text">Impressum</div>
        <p>
          <strong>Seitenbetreiber i.S.d. § 5 TMG</strong><br> 
          Pleasmile UG (haftungsbeschränkt)<br> 
          Steinring 56<br> 
          44789 Bochum<br>
          Deutschland
        </p> 
        <p>E-Mail: info@pleasmile.com<br> 
        </p> 
        <p>
          <strong>Geschäftsführer</strong><br> 
          Muhammed Ferit Kiziler<br> 
        </p>
        <p>
          <strong>Handelsregister</strong><br> 
          Amtsgericht Bochum, HRB 19163<br> 
        </p>
        <p>
          <strong>Umsatzsteuer-Identifikationsnummer</strong><br> 
          DE342195668<br> 
        </p>
        <p>
          <strong>Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV:</strong><br> 
          Muhammed Ferit Kiziler (Anschrift wie oben)
        </p> 
        <p>
          <strong>Informationen zur Online-Streitbeilegung</strong><br> 
          Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit.<br> 
          Diese Plattform finden Sie unter folgendem Link: https://ec.europa.eu/consumers/odr.<br> 
          Verbraucher können diese Plattform nutzen, um ihre Streitigkeiten aus Online-Verträgen beizulegen.
        </p> 
        <p>
          <strong>Hinweis gemäß § 36 VSBG</strong><br> 
          Wir werden nicht an alternativen Streitschlichtungsverfahren im Sinne des § 36 VSBG teilnehmen. Die Nutzung einer alternativen Schlichtungsstelle stellt keine zwingende Voraussetzung für das Anrufen zuständiger ordentlicher Gerichte dar.
        </p> 
        <p>
          <strong>Urheberrecht und Bildnachweise</strong><br> 
          Die Inhalte von www.pleasmile.com sind - soweit nicht abweichend angegeben - urheberrechtlich geschützt.<br> 
          Verwendete Fotografien sind ggf. mit Bildnachweisen gekennzeichnet oder unten aufgeführt, soweit sie nicht selbst angefertigt wurden.<br> 
          Die Verwendung von Fotografien auf Drittseiten ist nur im Rahmen der jeweiligen Lizenz der Urheber möglich.
        </p> 
      </div>
      <Footer />
    </div>
  </section>
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  name: "Imprint",
  components: {
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/colors.scss";

.imprint {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: $page-background-color-primary-dark;
}

.imprint-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
}

.imprint-content {
  font-family: Dosis;
  font-weight: 400;
  font-size: 18px;
  color: $text-color-primary-light;
  max-width: 600px;
  margin: 40px 40px 40px 40px;
}

.imprint-title {
  display: inline-block;
  margin-bottom: 40px;
}
</style>
